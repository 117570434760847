(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name login.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('login')
    .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl(RCRUsers, Events, $scope, $localStorage, $location, $timeout, $rootScope, Aside, Alerts) {
    var vm = this;
    vm.ctrlName = 'LoginCtrl';
    $scope.$storage = $localStorage;

    vm.isLoginPage = true;
	Aside.reqId = undefined;

    if(RCRUsers.isAuthenticated()){
      RCRUsers.logout();
      $scope.$storage = {};
      $scope.$storage.user = {};
      $localStorage.loggedInUser = {};
    }


    if($rootScope.pw) {
      vm.credentials = {};
      vm.credentials.password = $rootScope.pw;
      $rootScope.pw = false;
    }


    vm.submitLogin = function () {
      $scope.$storage = $localStorage;
      $scope.$storage.user = $scope.$storage.user || {};
      vm.isLoading = true;
      vm.error = false;
      vm.resendMsg = false;

      $timeout(function() {
        RCRUsers.login({rememberMe: false}, vm.credentials, function () {
          var next = ($location.nextAfterLogin && $location.nextAfterLogin != '/login') ? $location.nextAfterLogin : '/home';

          RCRUsers.getCurrent({"filter": {"include": ["UserTypes"]}}).$promise.then(function (result) {
            Events.create({
				"eventType":"login", 
				"eventData":result, 
				"userId": result.id
			});
            if(result.emailVerified == 0) {
              $scope.$storage.user = {};
              vm.error = "Please verify your email address";
              $timeout(function() {vm.error = '';},3000);
              vm.isLoading = false;
            }else if(result.activated == 0) {
              $scope.$storage.user = {};
              vm.error = "Email address is no longer activated";
              $timeout(function() {vm.error = '';},3000);
              vm.isLoading = false;
            }else {
              $scope.$storage.user = result;
              $scope.$storage.loggedInUser = result;
              $localStorage = result;
              $location.nextAfterLogin = null;

              $location.path(next);
              vm.resendMsg = "Login successful";
              vm.isLoading = false;
            }
          });

        }, function () {
          $scope.$storage.user = {};

          vm.error = "Login details incorrect";
          $timeout(function() {vm.error = '';},3000);
          vm.isLoading = false;

        });

      },1000);
    };

    vm.resend = function (email) {
      RCRUsers.sendVerification({"email": email});
	  Alerts.addAlert('ok',"Verification email re-sent, please check your emails.");
    }

  }
}());
